<template>
    <div class="Severity">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>중증도별 통계현황</h2>
                </div>
                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc">조회</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <table class="table table-profile">
                        <colgroup>
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field text-center font-weight-bold">일자</th>
                            <th class="field text-center font-weight-bold">무증상</th>
                            <th class="field text-center font-weight-bold">경증</th>
                            <th class="field text-center font-weight-bold">중등증</th>
                            <th class="field text-center font-weight-bold">중증</th>
                            <th class="field text-center font-weight-bold">최중증</th>
                            <th class="field text-center font-weight-bold">합계</th>
                        </tr>
                        <tr v-for="(data, index) in dataList" :key="index">
                            <td class="text-center">{{ data.fregDate }}</td>
                            <td class="text-center">{{ data.fsevere1 | comma }} 명</td>
                            <td class="text-center">{{ data.fsevere2 | comma }} 명</td>
                            <td class="text-center">{{ data.fsevere3 | comma }} 명</td>
                            <td class="text-center">{{ data.fsevere4 | comma }} 명</td>
                            <td class="text-center">{{ data.fsevere5 | comma }} 명</td>
                            <td class="text-center">{{ data.fsevere6 | comma }} 명</td>
                            <!--<td class="text-center" @click="searchDetailFunc('최중증', data.f_date, 'Lv5')">{{ data.Lv5 | comma }} 명</td>-->
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th class="field text-center font-weight-bold">총계</th>
                            <td class="text-center">{{ sum_Lv1 | comma }} 명</td>
                            <td class="text-center">{{ sum_Lv2 | comma }} 명</td>
                            <td class="text-center">{{ sum_Lv3 | comma }} 명</td>
                            <td class="text-center">{{ sum_Lv4 | comma }} 명</td>
                            <td class="text-center">{{ sum_Lv5 | comma }} 명</td>
                            <td class="text-center">{{ sum_Lv6 | comma }} 명</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Severity',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),
            dataList : [],

            sum_Lv1 : 0,
            sum_Lv2 : 0,
            sum_Lv3 : 0,
            sum_Lv4 : 0,
            sum_Lv5 : 0,
            sum_Lv6 : 0,
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        console.log(this.$route.params.sch_date_start);

        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;

            this.searchListFunc();
        }else{
            this.searchListFunc();
        }

    },
    methods: {
        // 리스트 검색
        searchListFunc() {
            let params = `{"fromRegDt": "${this.dateToString(this.sch_date_start)}", "toRegDt": "${this.dateToString(this.sch_date_end)}"}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/severe?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsSymptom');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('f_admin_id', this.userDataAdminId);
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            let sum_Lv1 = 0
            let sum_Lv2 = 0
            let sum_Lv3 = 0
            let sum_Lv4 = 0
            let sum_Lv5 = 0
            let sum_Lv6 = 0

            result.list.forEach(function (item) {
                sum_Lv1 = parseInt(item.fsevere1) +  sum_Lv1;
                sum_Lv2 = parseInt(item.fsevere2) +  sum_Lv2;
                sum_Lv3 = parseInt(item.fsevere3) +  sum_Lv3;
                sum_Lv4 = parseInt(item.fsevere4) +  sum_Lv4;
                sum_Lv5 = parseInt(item.fsevere5) +  sum_Lv5;
            });

            this.sum_Lv1 = sum_Lv1;
            this.sum_Lv2 = sum_Lv2;
            this.sum_Lv3 = sum_Lv3;
            this.sum_Lv4 = sum_Lv4;
            this.sum_Lv5 = sum_Lv5;
            this.sum_Lv6 = sum_Lv1 + sum_Lv2 + sum_Lv3 + sum_Lv4 + sum_Lv5;
        },
        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.title = title;
            data.date = date;
            data.idx = idx;
            data.sch_type = 'symptom';

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_symp.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }
            this.excelExport(url);
            */

            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}", 
                "toRegDt": "${this.dateToString(this.sch_date_end)}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/severe?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            let totalSevere1 = 0; // 총계 초기화
            let totalSevere2 = 0; // 총계 초기화
            let totalSevere3 = 0; // 총계 초기화
            let totalSevere4 = 0; // 총계 초기화
            let totalSevere5 = 0; // 총계 초기화
            let totalSevere6 = 0; // 총계 초기화

            // 엑셀 헤더 추가
            excelData.push(['일자', '무증상', '경증', '중등증',	'중증', '최중증', '합계']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                
                let sum1 = data.fsevere1;
                totalSevere1 += sum1; // 총계 누적
                
                let sum2 = data.fsevere2;
                totalSevere2 += sum2; // 총계 누적
                
                let sum3 = data.fsevere3;
                totalSevere3 += sum3; // 총계 누적
                
                let sum4 = data.fsevere4;
                totalSevere4 += sum4; // 총계 누적
                
                let sum5 = data.fsevere5;
                totalSevere5 += sum5; // 총계 누적
                
                let sum6 = data.fsevere6;
                totalSevere6 += sum6; // 총계 누적

                excelData.push([
                    data.fregDate, 
                    data.fsevere1, 
                    data.fsevere2, 
                    data.fsevere3, 
                    data.fsevere4, 
                    data.fsevere5, 
                    data.fsevere6 
                ]);
            });

            // 총계 행 추가
            excelData.push(['총계', totalSevere1, totalSevere2, totalSevere3, 
            totalSevere4, totalSevere5, totalSevere6]); // 총계 행 추가

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_중증도별.xlsx');
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        }
    }
}
</script>

<style scoped>
</style>
