import { render, staticRenderFns } from "./severity.vue?vue&type=template&id=8aecb22a&scoped=true&"
import script from "./severity.vue?vue&type=script&lang=js&"
export * from "./severity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aecb22a",
  null
  
)

export default component.exports